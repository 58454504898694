import auftragstatus from "@/constants/projektmappe/auftrag/status"
import angebotstatus from "@/constants/projektmappe/angebot/status"
import anfragestatus from "@/constants/projektmappe/anfrage/status"

import { gegenpruefer } from "@/config/projektmappe/angebot/fields"
import { bauleiter, status as auftragStatusField } from "@/config/projektmappe/auftrag/fields"

import angebotsabgabe from "@/constants/projektmappe/anfrage/angebotsabgabe"
import { angebotsabgabe as angebotsabgabeField, status as statusField } from "@/config/projektmappe/anfrage/fields"

export default [
  {
    path: 'projektmappe',
    component: () => import("@l/projektmappe/ProjektmappeLayout.vue"),
    children: [
      {
        path: '',
        name: 'projektmappe',
        component: () => import("@v/projektmappe/ProjektmappeStartseite.vue")
      },
      {
        path: 'anfrage',
        name: 'anfrageroot',
        component: () => import("@l/projektmappe/AnfrageLayout.vue"),
        children: [
          {
            path: 'list',
            name: 'anfrage.liste',
            component: () => import("@/views/projektmappe/anfrage/Anfrageliste.vue"),
            props: {
              additionalFields: [
                angebotsabgabeField,
                statusField
              ]
            }
          },
          {
            path: 'list/neue/',
            name: 'anfrage.liste.neue',
            component: () => import("@/views/projektmappe/anfrage/Anfrageliste.vue"),
            props: {

              title: "Neue Anfragen",
              angebotsabgabe: angebotsabgabe.keine_entscheidung,
              nichtAbgeschlossen: true,

            }
          },
          {
            path: 'list/abgeschlossen/',
            name: 'anfrage.liste.abgeschlossen',
            component: () => import("@/views/projektmappe/anfrage/Anfrageliste.vue"),
            props: {
              status: anfragestatus.abgeschlossen,
              title: "Abgeschlossene Anfragen",
              angebotsabgabe: angebotsabgabe.angebotsabgabe,
            }
          },
          {
            path: 'list/bearbeiter/ohne',
            name: 'anfrage.liste.unzugeordnet',
            component: () => import("@/views/projektmappe/anfrage/Anfrageliste.vue"),
            props: {
              ohneBearbeiter: true,
              angebotsabgabe: angebotsabgabe.angebotsabgabe,
              title: "Anfragen ohne Bearbeiter",
              nichtAbgeschlossen: true
            }
          },
          {
            path: 'list/bearbeiter/:bearbeiter',
            name: 'anfrage.liste.bearbeiter',
            component: () => import("@/views/projektmappe/anfrage/Anfrageliste.vue"),
            props: route => {
              return {
                bearbeiter: route.params.bearbeiter,
                angebotsabgabe: angebotsabgabe.angebotsabgabe,
                nichtAbgeschlossen: true
              }
            }
          },
          {
            path: 'list/oeffentlich',
            name: 'anfrage.liste.oeffentlich',
            component: () => import("@/views/projektmappe/anfrage/Anfrageliste.vue"),
            props: {
              oeffentlich: true,
              angebotsabgabe: angebotsabgabe.keine_entscheidung,
              nichtAbgeschlossen: true,
              title: 'Öffentliche Anfragen'
            }
          },
          {
            path: 'list/nichtoeffentlich',
            name: 'anfrage.liste.nichtoeffentlich',
            component: () => import("@/views/projektmappe/anfrage/Anfrageliste.vue"),
            props: {
              oeffentlich: false,
              angebotsabgabe: angebotsabgabe.keine_entscheidung,
              nichtAbgeschlossen: true,
              title: 'Nichtöffentliche Anfragen'
            }
          },
          {
            path: 'list/kein_angebot',
            name: 'anfrage.liste.kein_angebot',
            component: () => import("@/views/projektmappe/anfrage/Anfrageliste.vue"),
            props: {
              angebotsabgabe: angebotsabgabe.keine_angebotsabgabe,
              title: "Anfragen ohne Angebotsabgabe",
              status: anfragestatus.abgeschlossen,
            }
          },
          {
            path: 'list/laufende',
            name: 'anfrage.liste.laufende',
            component: () => import("@/views/projektmappe/anfrage/Anfrageliste.vue"),
            props: {
              angebotsabgabe: angebotsabgabe.angebotsabgabe,
              title: "Laufende Anfragen",
              nichtAbgeschlossen: true
            }
          },
          {
            path: 'import',
            name: 'anfrage.import',
            component: () => import("@/views/projektmappe/anfrage/Anfrageimport.vue")
          },
          {
            path: 'new',
            name: 'anfrage.new',
            component: () => import("@/views/projektmappe/anfrage/Anfrage.vue"),
            props: true
          },
          {
            path: ':id',
            name: 'anfrage.id',
            component: () => import("@/views/projektmappe/anfrage/Anfrage.vue"),
            props: true
          },
          {
            path: ':id/:tab',
            name: 'anfrage.id.tab',
            component: () => import("@/views/projektmappe/anfrage/Anfrage.vue"),
            props: true
          },
        ]
      },
      {
        path: 'angebot',
        name: 'angebotroot',
        component: () => import("@l/projektmappe/AngebotLayout.vue"),
        children: [
          {
            path: 'list',
            name: 'angebot.liste',
            component: () => import("@/views/projektmappe/angebot/Angebotliste.vue")
          },
          {
            path: 'list/pruefung_erforderlich',
            name: 'angebot.liste.pruefung_erforderlich',
            component: () => import("@/views/projektmappe/angebot/Angebotliste.vue"),
            props: {
              status: angebotstatus.freigabe_erforderlich,
              title: "Angebote mit erforderlicher Prüfung",
              additionalFields: [
                gegenpruefer
              ]
            }
          },
          {
            path: 'import',
            name: 'angebot.import',
            component: () => import("@v/projektmappe/angebot/AngebotImport.vue")
          },
          {
            path: 'new',
            name: 'angebot.new',
            component: () => import("@/views/projektmappe/angebot/Angebot.vue"),
            props: true
          },
          {
            path: ':id',
            name: 'angebot.id',
            component: () => import("@/views/projektmappe/angebot/Angebot.vue"),
            props: true
          },
          {
            path: ':id/:tab',
            name: 'angebot.id.tab',
            component: () => import("@/views/projektmappe/angebot/Angebot.vue"),
            props: true
          },
        ]
      },
      {
        path: 'auftrag',
        name: 'auftragroot',
        component: () => import("@l/projektmappe/AuftragLayout.vue"),
        children: [
          {
            path: 'list',
            name: 'auftrag.liste',
            component: () => import("@/views/projektmappe/auftrag/Auftragliste.vue"),
            props: {
              additionalFields: [
                auftragStatusField,
                bauleiter
              ]
            }

          },
          {
            path: 'list/aktuell',
            name: 'auftrag.liste.aktuell',
            component: () => import("@/views/projektmappe/auftrag/Auftragliste.vue"),
            props: {
              status: auftragstatus.aktuell,
              title: "Aktuelle Aufträge",
              additionalFields: [
                bauleiter
              ]
            }
          },
          {
            path: 'list/erledigt',
            name: 'auftrag.liste.erledigt',
            component: () => import("@/views/projektmappe/auftrag/Auftragliste.vue"),
            props: {
              status: auftragstatus.erledigt,
              title: "Erledigte Aufträge",
            }
          },
          {
            path: 'list/storno',
            name: 'auftrag.liste.storno',
            component: () => import("@/views/projektmappe/auftrag/Auftragliste.vue"),
            props: {
              status: auftragstatus.storno,
              title: "Stornierte Aufträge",
            }
          },
          {
            path: 'list/bauleiter/ohne',
            name: 'auftrag.liste.ohnebauleiter',
            component: () => import("@/views/projektmappe/auftrag/Auftragliste.vue"),
            props: route => {
              return {
                status: auftragstatus.aktuell,
                bauleiter: route.params.bauleiter,
                ohneBauleiter: true,
                title: "Aufträge ohne Bauleiter"
              }
            }
          },
          {
            path: 'list/bauleiter/:bauleiter',
            name: 'auftrag.liste.bauleiter',
            component: () => import("@/views/projektmappe/auftrag/Auftragliste.vue"),
            props: route => {
              return {
                status: auftragstatus.aktuell,
                bauleiter: route.params.bauleiter
              }
            }
          },
          {
            path: 'import',
            name: 'auftrag.import',
            component: () => import("@/views/projektmappe/auftrag/Auftragimport.vue")
          },
          {
            path: 'import/eingangsrechnung',
            name: 'auftrag.import.eingangsrechnung',
            component: () => import("@/views/projektmappe/auftrag/AuftragimportEingangsrechnung.vue")
          },
          {
            path: 'import/ausgangsrechnung',
            name: 'auftrag.import.ausgangsrechnung',
            component: () => import("@/views/projektmappe/auftrag/AuftragimportAusgangsrechnung.vue")
          },
          {
            path: 'import/zeittabelle',
            name: 'auftrag.import.zeittabelle',
            component: () => import("@/views/projektmappe/auftrag/AuftragimportZeittabelle.vue")
          },
          {
            path: 'import/cashmanagement',
            name: 'auftrag.import.cashmanagement',
            component: () => import("@/views/projektmappe/auftrag/AuftragimportCashmanagement.vue")
          },
          {
            path: 'new',
            name: 'auftrag.new',
            component: () => import("@/views/projektmappe/auftrag/Auftrag.vue"),
            props: true
          },
          {
            path: ':id',
            name: 'auftrag.id',
            component: () => import("@/views/projektmappe/auftrag/Auftrag.vue"),
            props: true
          },
          {
            path: ':id/zeitstrahl',
            name: 'auftrag.zeitstrahl',
            component: () => import("@/views/projektmappe/auftrag/AuftragZeitstrahl.vue"),
            props: true
          },
          {
            path: ':id/:tab',
            name: 'auftrag.id.tab',
            component: () => import("@/views/projektmappe/auftrag/Auftrag.vue"),
            props: true
          },
        ]
      },
    ]
  }
]